import Head from "next/head"

export function NextHead({
    title,
    description,
    keywords,
    image
}) {
    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="keywords" content={keywords} />
            <meta property="og:image" content={process.env.IMAGE_LINK + image} />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={process.env.BASE_URL} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={description} />
            {/* Temporary tag manager */}
            {process.env.REACT_APP_NODE_ENV === "production" ? 
            typeof window !== "undefined" ?
            <>
            <script>
                {(function(w,d,s,l,i){
                w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),
                    dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);
                })
                (window,document,'script','dataLayer','GTM-KLRSKS4C')};
                </script>
                </> : ''
             : ''}
             <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    name: "SEO Best Practices",
                    description: "Guide to SEO optimization in Next.js",
                    keywords: [
                        "upbeat spanish wedding songs",
                        "spanish wedding music",
                        "north jersey wedding bands",
                        "ny wedding bands",
                        "nj wedding bands",
                        "best wedding bands nyc",
                        "nyc wedding music bands",
                        "best wedding bands new york",
                        "new york wedding band",
                        "new york wedding bands",
                        "wedding band nyc",
                        "best instruments for wedding ceremony",
                        "best latin dance songs for weddings",
                        "hottest new love songs",
                        "wedding music bands nyc",
                        "famous spanish dance songs",
                        "good new love songs",
                        "best wedding bands nj",
                        "latin songs for weddings",
                        "latin wedding songs",
                        "wedding bands ny",
                        "instruments for wedding ceremony",
                        "new jersey wedding bands",
                        "wedding bands nj",
                        "best entry song for couple",
                        "new york wedding band cost",
                        "nyc wedding band",
                        "south jersey wedding bands",
                        "best new love songs",
                        "great new love songs",
                        "wedding bands new york",
                        "best spanish party songs",
                        "best spanish dance songs",
                        "ct wedding bands",
                        "nyc wedding bands",
                        "4 star point stamford ct",
                        "romantic songs in spanish for weddings",
                        "the around town",
                        "wedding ceremony instruments",
                        "abba father daughter dance",
                        "best wedding bands south jersey",
                        "nyc wedding band cost",
                        "wedding band nj",
                        "songs about new love",
                        "songs about love new",
                        "great spanish dance songs",
                        "spanish music for weddings",
                        "best dancing spanish songs",
                        "bar mitzvah entertainers",
                        "how much does a band cost for a wedding",
                        "songs like suavemente",
                        "spanish songs for first dance at wedding",
                        "upbeat wedding reception songs",
                        "new songs about love",
                        "upbeat wedding dance songs",
                        "upbeat wedding party dance songs",
                        "mother daughter songs",
                        "indian trail club",
                        "indian trail club franklin lakes nj",
                        "love songs for new love",
                        "live wedding bands near me",
                        "hispanic wedding songs",
                        "mudari creative",
                        "famous spanish dancing songs",
                        "love songs newer",
                        "famous spanish party songs",
                        "good spanish dance songs",
                        "good spanish party songs",
                        "new songs about relationships",
                        "mother daughter dance wedding",
                        "spanish first dance songs",
                        "band hire cost",
                        "bands in ct",
                        "average cost of band for wedding",
                        "band prices",
                        "wedding bands nyc",
                        "spanish wedding songs",
                        "bands for weddings near me",
                        "upbeat wedding songs",
                        "songs for new lovers",
                        "indian trail club nj",
                        "popular spanish dance songs",
                        "songs for new love",
                        "upbeat wedding music",
                        "best songs about new love",
                        "top spanish club songs",
                        "wedding band costs",
                        "popular spanish party songs",
                        "top spanish party songs",
                        "band for wedding cost",
                        "ct band",
                        "how much is bands",
                        "songs for a new love",
                        "how much does it cost to hire a wedding band",
                        "latin wedding music",
                        "latino wedding music",
                        "romantic spanish wedding songs",
                        "le chateau south salem reviews",
                        "finding new love songs",
                        "love songs about new love",
                        "songs about a new love",
                        "indian trail club in franklin lakes nj",
                        "birch hill catering",
                        "songs for mom and daughter wedding",
                        "songs for new relationships",
                        "popular spanish songs dance",
                        "spanish dance song",
                        "best spanish songs for dancing",
                        "mom and daughter songs for wedding",
                        "mother of bride songs",
                        "best dance songs spanish",
                        "songs new love",
                        "wedding couple entry songs",
                        "wedding songs first dance spanish",
                        "after party band nj",
                        "hispanic wedding music",
                        "indian trails club",
                        "wedding entertainment bands",
                        "mother daughter dance songs wedding",
                        "piano wedding songs to walk down the aisle",
                        "songs from mother to daughter on wedding day",
                        "best spanish songs to dance to",
                        "bnds in town",
                        "good spanish songs to dance to",
                        "mom daughter song",
                        "songs from mom to daughter for wedding",
                        "dance songs for mother and daughter",
                        "mother daughter songs for a wedding",
                        "mother daughter wedding dance songs",
                        "new relationship songs",
                        "song for new relationship",
                        "best party songs spanish",
                        "best recent love songs",
                        "couple entrance songs",
                        "couple entrance songs for wedding",
                        "old spanish party songs",
                        "spanish songs to dance",
                        "spanish songs to dance to",
                        "wedding songs first dance in spanish",
                        "mother daughter dance songs for wedding",
                        "price of band",
                        "best nyc bands",
                        "famous spanish club songs",
                        "foxfire inn mount tremper ny",
                        "mom daughter songs",
                        "best wedding dance songs upbeat",
                        "spanish dance songs",
                        "upbeat wedding party songs",
                        "wedding band prices",
                          "wedding ceremony piano songs",
                          "songs mothers to daughters",
                          "good songs for new relationships",
                          "live bands for weddings near me",
                          "top current love songs",
                          "mother son wedding songs",
                          "father daughter wedding songs",
                          "mother daughter dance songs",
                          "wedding entrance",
                          "new york bands",
                          "daughter song to mom",
                          "songs about daughters and mothers",
                          "songs about wanting love",
                          "spain club song",
                          "one hanover square nyc",
                          "top mom son wedding songs",
                          "good wedding processional songs",
                          "mom and daughters songs",
                          "mother and daughter dance songs for wedding",
                          "candlelight farms new milford connecticut",
                          "love songs for new relationships",
                          "popular wedding processional songs",
                          "wedding father daughter songs in spanish",
                          "a listers band",
                          "entertainment nyc",
                          "mother and bride dance songs",
                          "songs for daughters from mothers",
                          "songs from mothers to their daughters",
                          "songs mom to daughter",
                          "wedding love songs r&b",
                          "enjoy the band nj",
                          "wedding r&b songs",
                          "best wedding songs for bride and groom entrance",
                          "budget bands for hire",
                          "mother daughter dance for wedding",
                          "songs for mom and daughter",
                          "daddy daughter wedding songs",
                          "wedding songs for mother and son",
                          "daughter songs from mom",
                          "new love song",
                          "r&b songs wedding",
                          "good mother daughter songs",
                          "wedding songs r&b",
                          "good mom and daughter songs",
                          "good mother son wedding songs",
                          "songs to dance with mom and daughter at wedding",
                          "best mother and son wedding songs",
                          "daughter and mom songs",
                          "songs for mother and daughter",
                          "songs for slow dances",
                          "great wedding processional songs",
                          "spanish music for dance",
                          "songs for daughter from mom",
                          "the university club manhattan",
                          "songs from a mom to her daughter",
                          "good recent love songs",
                          "most popular processional wedding songs",
                          "r&b love wedding songs",
                          "songs about mom and daughter",
                          "a town band",
                          "the wood soundtrack wedding song",
                          "top processional songs",
                          "appropriate spanish songs",
                          "how much does entertainment cost",
                          "songs about wanting to be with someone",
                          "best wedding band videos",
                          "mother som dance songs",
                          "songs to daughters from mom",
                          "daughter to mom songs",
                          "mom songs from daughter",
                          "mother daughter wedding dance",
                          "mother and daughter dance",
                          "best mom son songs",
                          "mother to daughter songs for wedding",
                          "song from mother to daughter",
                          "songs about daughters from mom",
                          "band from new york",
                          "essex sussex spring lake",
                          "essex and sussex in spring lake nj",
                          "mother daughter songs for wedding",
                          "best daughter mother songs",
                          "best r&b wedding songs",
                          "processional songs for wedding",
                          "band new jersey",
                          "best contemporary love songs",
                          "mother and daughter dance songs",
                          "wedding songs for mother daughter dance",
                          "best hispanic dance songs",
                          "best mom and son wedding songs",
                          "wedding do not play songs",
                          "daughter and father wedding songs in spanish",
                          "daughter song from mom",
                          "from mother to daughter songs",
                          "good mom son wedding songs",
                          "mom daughter dance",
                          "mother daughter spanish songs",
                          "a mother's song to her daughter",
                          "the perfect match 2016 soundtrack",
                          "upbeat love songs for weddings",
                          "how to dance to spanish music",
                          "wedding party band",
                          "daughter mother songs",
                          "daughter to mother songs",
                          "love slow dance songs",
                          "spanish dance club songs",
                          "slow dance songs",
                          "mom son wedding songs",
                          "best mother son dance songs for wedding",
                          "mother and daughter dance for wedding",
                          "songs slow dance",
                          "good mother and son wedding songs",
                          "bands from new york",
                          "songs mother daughter",
                          "wedding band music",
                          "wedding entrance ideas reception",
                          "songs for mother daughter dance",
                          "songs to not play at a wedding",
                          "top mother and son wedding songs",
                          "wedding live band cost",
                          "bride and mother dance songs",
                          "bride mother dance songs",
                          "how much does it cost to book a band",
                          "mother and son songs in spanish",
                          "wedding songs for mother's entrance",
                          "lake george chateau",
                          "morher son dance songs",
                          "spanish mother and son songs",
                          "wedding processional songs for piano",
                          "bridgerton wedding songs",
                          "mother son wedding songs to dance to",
                          "fun mother daughter songs",
                          "good father daughter wedding songs",
                          "best mother son wedding songs",
                          "dance songs slow",
                          "dance mother and daughter",
                          "wedding ceremony songs for piano",
                          "worst wedding songs",
                          "songs about finding love",
                          "great mother son wedding songs",
                          "best country mother son wedding songs",
                          "love songs for new lovers",
                          "mexican songs for weddings",
                          "spanish songs club",
                          "the essex and sussex spring lake nj",
                          "best mother son song",
                          "mother to son wedding songs",
                          "r&b wedding song",
                          "the chateau on the lake bolton landing ny",
                          "wedding songs for mom",
                          "how much are bands",
                          "love songs to",
                          "mom and daughter song",
                          "mother son wedding songs 2024",
                          "le chateau spring lake nj",
                          "wedding songs for father daughter",
                          "a song from mother to daughter",
                          "audrey's farmhouse wedding cost",
                          "best club spanish songs",
                          "top recent love songs",
                          "wedding live music",
                          "mother and son wedding songs",
                          "the crescent beach club",
                          "mother and daughter songs",
                          "brookdale country club",
                          "essex sussex spring lake nj",
                          "candlelight farms inn new milford ct",
                          "top father daughter wedding songs",
                          "bringing it around town",
                          "mother and daughter dance at wedding",
                          "songs for daughter from mother",
                          "daddy daughter songs in spanish",
                          "mother daughter dance at wedding",
                          "the key to gramercy park",
                          "wedding songs father daughter",
                          "mother daughter dances",
                          "a song from a mother to her daughter",
                          "mom son wedding song",
                          "best r&b wedding songs walk down aisle",
                          "candlelight farm inn",
                          "mother and son songs spanish",
                          "live bands wedding",
                          "mother son songs spanish",
                          "rock wedding entrance songs",
                          "song about mother and daughter",
                          "songs to avoid at weddings",
                          "mother and daughter song",
                          "wedding songs to dance with mom",
                          "best new jersey bands",
                          "entertainment ent",
                          "live bands for events",
                          "brooklake country club",
                          "dad and daughter wedding songs",
                          "mother and daughter wedding dance",
                          "mother daughter song",
                          "best mom son dance songs",
                          "dancing songs in spanish",
                          "bands and town",
                          "average cost of wedding bands",
                          "wedding live band",
                          "worst wedding music",
                          "r&b daddy daughter songs",
                          "top mom and son wedding dance songs",
                          "from mom to daughter songs",
                          "piano wedding ceremony",
                          "spanish songs upbeat",
                          "fun songs for wedding processional",
                          "popular r&b wedding songs",
                          "top wedding r&b songs",
                          "lookout lyrics",
                          "piano processional songs wedding",
                          "best songs for mother groom dance",
                          "r & b wedding songs",
                          "a song for a mother and daughter",
                          "mother/son wedding songs",
                          "rnb wedding song",
                          "songs from mother to daughter",
                        "mother/son wedding dance songs",
                        "classic rock wedding songs",
                        "mother son dance songs 2024",
                        "popular mother son songs for wedding",
                        "songs to walk into the wedding reception",
                        "wedding bands with brass",
                        "cute songs about love",
                        "live band for wedding price",
                        "love songs for weddings r&b",
                        "songs being in love",
                        "wedding songs for mom and son to dance to",
                        "how much is a band",
                        "wedding songs dancing party",
                        "current popular love songs",
                        "lawn club nyc",
                        "songs about mother and daughter",
                        "ideas for wedding reception entrance",
                        "r&b wedding music",
                        "mother and son dance to roll it",
                        "piano wedding ceremony songs",
                        "top mother son songs for weddings",
                        "dance music in spanish",
                        "pop spanish songs 2023",
                        "the crescent beach club photos",
                        "best song for mom and son at wedding",
                        "cover bands nyc",
                        "songs about couples",
                        "spanish discoteca songs",
                        "best wedding father daughter songs",
                        "r&b love songs wedding",
                        "wedding recessional music piano",
                        "songs for relationships",
                        "the wedding entertainment",
                        "rnb wedding songs",
                        "bob seger wedding songs",
                        "crescent beach club price per person",
                        "r n b wedding songs",
                        "greenville country club",
                        "mom groom wedding songs",
                        "root for the home team",
                        "the university club new york ny",
                        "classic spanish songs",
                        "mother son dance songs wedding",
                        "new york band",
                        "best mother and daughter songs",
                        "cool mother daughter songs",
                        "songs for new love for him",
                        "wedding songs for groom and his mother",
                        "hit song in spanish",
                        "r&b wedding songs for walking down the aisle",
                        "wedding songs walking into reception",
                        "best upbeat top hits",
                        "mother son dance songs",
                        "piano music for wedding processional",
                        "top ten mother and son wedding songs",
                        "pine orchard country club wedding",
                        "abba song about daughter",
                        "eturnity band",
                        "songs for the mother son dance at a wedding",
                        "wedding entrance ideas wedding party",
                        "son and mother country songs",
                        "candlelight farms inn new milford",
                        "mother son wedding dance songs 2024",
                        "entertainment in nyc",
                        "love songs modern",
                        "a mother's love song for her son",
                        "popular son mother wedding songs",
                        "songs mother and son dance to at his wedding",
                        "how much do live wedding bands cost",
                        "top bridal processional songs",
                        "dad and daughter song lyrics",
                        "the sagamore resort wedding cost",
                        "the sagamore wedding cost",
                        "crescent beach club bayville",
                        "dance floor wedding",
                        "best mom daughter songs",
                        "contemporary father daughter dance songs",
                        "all around town",
                        "mother to daughter songs country",
                        "best wedding songs mother and son",
                        "live band for wedding",
                        "live music band for wedding",
                        "how much does a live band cost for a wedding",
                        "new relationship songs for him",
                        "wedding recessional piano music",
                        "pop spanish songs 2022",
                        "the lawn club nyc",
                        "brooklake country club nj",
                        "the crescent beach club bayville",
                        "dance songs for receptions",
                        "mother's song for daughter",
                        "colony club manhattan",
                        "dedication songs for daughters",
                        "dancing songs for a wedding",
                        "wedding classic rock songs",
                        "songs for moms at weddings",
                        "whitehouse station nj ryland inn",
                        "indian trail club photos",
                        "mothers songs to their daughters",
                        "dance songs for father daughter at the wedding",
                        "music for dancing at weddings",
                        "chateau new york",
                        "tri state wedding venues",
                        "bands in south jersey",
                        "wedding band for hire",
                        "wedding entertainment new jersey",
                        "piano wedding songs",
                        "best mother daughter songs",
                        "crescent beach club bayville ny",
                        "oldie spanish songs",
                        "1 hanover square",
                        "live band wedding",
                        "good mother son dance for wedding",
                        "wedding song brian mcknight",
                        "upbeat modern love songs",
                        "wedding entrance props",
                        "best party mexican songs",
                        "rock bridal party entrance songs",
                        "couple love songs",
                        "colony club new york",
                        "best dancing music for weddings",
                        "entrance of bride songs",
                        "colony club new york city",
                        "greatest father daughter dance",
                        "songs for meeting someone new",
                        "father and daughter song lyrics",
                        "top mom son dance songs",
                        "band for wedding",
                        "father daughter mother son dance songs",
                        "good daddy daughter wedding songs",
                        "wedding processional piano music",
                        "music wedding band",
                        "the colony club new york",
                        "country son and mom wedding songs",
                        "good mother son wedding dances",
                        "father to daughter songs in spanish",
                        "classic rock wedding music",
                        "great wedding playlist",
                        "popular mom and son wedding songs",
                        "trending love songs",
                        "wedding hall entrance songs",
                        "best songs father daughter",
                        "plaza hotel christmas tree",
                        "dance with father wedding songs",
                        "wedding entertainment",
                        "the garrison ny wedding",
                        "musique spanish",
                        "bride groom grand entrance songs",
                        "cool wedding intro songs",
                        "picture of a dance floor",
                        "good mother and son songs for weddings",
                        "christmas in the plaza",
                        "songs to dance with your mother",
                        "father daughter dance isn't she lovely",
                        "father and daughter wedding songs",
                        "club lawn",
                        "new years wedding",
                        "traditional spanish dance",
                        "hispanic dancing music",
                        "spain music dance",
                        "father and daughter first dance wedding songs",
                        "new year's eve wedding",
                        "classical wedding music for piano",
                        "spaniard dance",
                        "father and mother song",
                        "band new york",
                        "new york rock bands",
                        "wedding processional song ideas",
                        "indian trail club nj wedding",
                        "mom son wedding dance country",
                        "brittany soule",
                        "father and mother son",
                        "the palm house brooklyn wedding",
                        "hudson house wedding",
                        "spanish dance styles",
                        "father and daughter first dance songs",
                        "rod stewart wedding songs",
                        "top mother son dance songs for wedding",
                        "forever dance wedding",
                        "a song for mom from daughter",
                        "a wedding for the ages",
                        "the garrison new york wedding",
                        "songs for reception entrance for bride and groom",
                        "most popular wedding ceremony music",
                        "oheka castle new york",
                        "the russian tea room new york ny",
                        "essex & sussex condominium",
                        "funny mother groom dance songs",
                        "good songs for mother and son dance",
                        "top father and daughter dance songs",
                        "entrance of bride and groom songs",
                        "parents dance songs for wedding",
                        "contemporary love songs",
                        "wedding dance fun songs",
                        "entrance reception",
                        "bridal party entrance ideas reception",
                        "songs for son and mother to dance at his wedding",
                        "good son and mother songs for a wedding",
                        "daddy and daughter song lyrics",
                        "dances of spain",
                        "the garrison wedding",
                        "father daughter wedding dance music",
                        "sam bridges nursery greenwich",
                        "top father daughter wedding dance songs",
                        "the foundry new york wedding cost",
                        "write pretty",
                        "father songs r&b",
                        "rock bands from new york",
                        "song about dance floor",
                        "bands today",
                        "bar mitzvah new york",
                        "bat mitzvah band",
                        "songs for wedding dance with mom",
                        "country mom son dance songs",
                        "dance in spain",
                        "cover band new york",
                        "get on the dancefloor",
                        "the plaza nyc christmas",
                        "oceanbleu westhampton ny",
                        "lists band",
                        "delamar greenwich harbor wedding",
                        "good songs spanish",
                        "spanish good songs",
                        "song lyrics father and daughter",
                        "songs at weddings to dance to",
                        "mother groom songs wedding",
                        "songs for mother and son to dance at his wedding",
                        "dance spain",
                        "music band for wedding",
                        "eclectic encore nyc",
                        "music bands for wedding",
                        "songs for wedding dollar dance",
                        "mom and son dance wedding songs country",
                        "the foundry nyc wedding",
                        "father daughter song tim mcgraw",
                        "top mother and son dance songs",
                        "great spanish love songs",
                        "wedding halls new rochelle ny",
                        "christmas at the plaza hotel",
                        "soanish dance",
                        "father dance songs",
                        "the garrison new york",
                        "entertainers wedding",
                        "luther vandross songs for weddings",
                        "group dancing songs for wedding",
                        "entrance for party",
                        "top 10 mother and son wedding dance songs",
                        "songs for mom and son to dance to",
                        "songs from mother to son country",
                        "spain dance",
                        "spain dances",
                        "wedding dance songs parents",
                        "songs about new love for him",
                        "the park savoy estate in new jersey",
                        "music wedding",
                        "party entertainment nyc",
                        "spanish beautiful songs",
                        "famous popular piano songs",
                        "father daughter songs fun",
                        "farmhouse for wedding",
                        "funny ideas for wedding party entrance",
                        "who owns oheka castle",
                        "father daughter wedding first dance songs",
                        "ivory gold wedding",
                        "best modern love songs",
                        "entrance to wedding",
                        "popular daughter father wedding songs",
                        "top father and daughter wedding songs",
                        "wedding entrance reception",
                        "wedding parent dances",
                        "popular songs for mother and son dance at wedding",
                        "amenity band",
                        "wedding venues ny nj",
                        "music for wedding",
                        "top modern love songs",
                        "the crescent beach club in bayville",
                        "mother daughter songs for sweet 16",
                        "daughter getting married songs",
                        "bands with city names",
                        "and then comes marriage",
                        "nice wedding entrance songs",
                        "the foundry wedding",
                        "colony club wedding",
                        "mother's love for her son songs",
                        "grand wedding entrance ideas",
                        "awesome wedding entrances",
                        "whitby castle new york",
                        "good wedding music to dance to",
                        "american love songs",
                        "dancing bridal party",
                        "modern upbeat love songs",
                        "popular dance of spain",
                        "the trail club",
                        "park savoy estate florham park nj",
                        "upbeat mother-daughter songs",
                        "upbeat mother daughter songs",
                        "contemporary songs to walk down the aisle",
                        "songs about mothers love for her son",
                        "the foundry long island city wedding",
                        "songs about being together",
                        "bat mitzvah in new york",
                        "long island wedding band",
                        "popular songs in spanish and english",
                        "the colony club",
                        "ivory town connecticut",
                        "love in another town",
                        "mother groom songs for wedding",
                        "reunited love songs",
                        "wedding musicians for hire",
                        "types of spanish dances",
                        "marriage proposal central park",
                        "best current love songs",
                        "entertain new",
                        "mother daughter sweet 16 songs"
                      ],
                }),
                }}
            />
        </Head>
    )
}
